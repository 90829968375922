import ReactOnRails from 'react-on-rails';

import '../assets/styles/app.scss';

import 'jquery/src/jquery'
import 'jquery-ujs/src/rails'

import LinkableMediaGrid from '../bundles/Public/components/LinkableMediaGrid/LinkableMediaGrid.jsx';
import IdentityHeader from '../bundles/Public/components/IdentityHeader/IdentityHeader.jsx';

ReactOnRails.register({
  LinkableMediaGrid, IdentityHeader
});
