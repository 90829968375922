import React, { PropTypes } from 'react';
import update from 'immutability-helper';
import css from './LinkableMediaGrid.scss'
import request from 'axios'
import moment from 'moment'
import MediaGrid from '../../../LinkMyPhotos/components/MediaGrid/MediaGrid.jsx'

const mediaPerPage = 18
const refreshThreshold = 5

function isActive(media) {
  const taggedProduct = media.tagged_products.length > 0 ? media.tagged_products[0] : undefined
  const link = taggedProduct ? taggedProduct.uri : media.uri
  const isProduct = media.link_type == 'product'
  return (!isProduct && media.uri) || (isProduct && taggedProduct)
}

export default class LinkableMediaGrid extends React.Component {

  constructor(props, _railsContext) {
    super(props);
    this.state = {
      page: 1,
      identity: this.props.identity,
      media: this.props.media,
      isRefreshingMedia: this.props.identity.refreshing_media,
      isLoadingMedia: this.props.identity.loading_media,
      status: 'loading'
    }
  }

  componentDidMount() {
    if (this.props.identity.loading_media || this.props.identity.refreshing_media) {
      this.poll()
    }
  }

  poll() {
    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    request
      .get('/app/identities/' + this.props.identity.id + '/poll_short.json', requestConfig)
      .then((response) => {
        const filteredMedia = response.data.media.filter(media => isActive(media))
        const trimmedMedia = this.props.free ? filteredMedia.slice(0, 3) : filteredMedia
        const newMedia = this.mergeMedia(this.state.media, trimmedMedia)
        const trimmedNewMedia = this.props.free ? newMedia.slice(0, 3) : newMedia
        this.setState({
          media: trimmedNewMedia,
          isRefreshingMedia: response.data.refreshing_media,
          isLoadingMedia: response.data.loading_media
        })

        if (response.data.loading_media || response.data.refreshing_media) {
          setTimeout(function() { this.poll() }.bind(this), 5000)
        }
      })
      .catch(error => {
        console.log('poll error: ' + error)
      })
  }

  mergeMedia(old, updated) {
    var o = {}
    old.forEach(function(v) {
      o[v.id] = v
    })
    updated.forEach(function(v) {
      o[v.id] = v
    })
    var r = []
    for (var p in o) {
      if (o.hasOwnProperty(p)) {
        r.push(o[p])
      }
    }
    const sorted = r.sort(function(a, b) {
      return new Date(b.created_time) - new Date(a.created_time)
    })
    return sorted
  }

  loadMore(e) {
    e.preventDefault();
    this.fetchPage(this.state.page + 1);      
  }

  fetchPage(page) {
    this.setState({
      status: 'fetching'
    })
    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    request
      .get('/app/identities/' + this.props.identity.id + '/load_more.json?page=' + page, requestConfig)
      .then((response) => {
        if (response.data.length > 0) {
          const newMedia = update(this.state.media, {$push: response.data})
          
          var status = 'loaded'
          if (response.data.length < mediaPerPage) {
            status = 'done'
          }

          this.setState({
            page: page,
            media: newMedia,
            status: status
          })

        } else {
          this.setState({
            status: 'done'
          })
        }

      })
      .catch(error => {
        console.log('load more links error: ' + error)
      })

  }

  handleMediaClick(media) {
    if (this.props.trackable) {
      this.track(media)
    } else {
      this.visit(media)
    }
  }

  handleMediaRefresh(media) {    
    var updatedMedia = update(media, {fetching: {$set: true}});
    var mediaIndex = this.state.media.findIndex(function(m) {
      return m.id == media.id;
    });
    var newMedia = update(this.state.media, {
      $splice: [[mediaIndex, 1, updatedMedia]]
    });
    this.setState({
      media: newMedia
    });

    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    request
      .put('/app/media/' + media.id + '/refresh.json', null, requestConfig)
      .then((response) => {
        setTimeout(function() { this.pollMedia(media) }.bind(this), 5000)
      })
      .catch(error => {
        console.log('refresh media error: ' + error)
      })
  }

  track(media) {
    const trackableId = media.id
    const trackableType = 'Media'
    const name = 'click'
    const json = {}
    const identityId = media.identity_id

    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    const data = {
      trackable_id: trackableId,
      trackable_type: trackableType,
      name: name,
      json: json,
      identity_id: identityId
    }

    request
      .post('/app/events.json', data, requestConfig)
      .then((response) => {
        this.visit(media)
      })
      .catch(error => {
        console.log('error: ' + error)
        this.visit(media)
      })
  }

  // Poll the media to see if it's done fetching
  pollMedia(media) {
    const _this = this

    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    request
      .get('/app/media/' + media.id + '/poll.json', requestConfig)
      .then((response) => {
        console.log(`media polled fetching=${response.data.fetching} image_thumbnail_url=${response.data.image_thumbnail_url} image.standard_resolution.url=${response.data.image.standard_resolution.url}`)

        const data = response.data

        var updatedMedia = update(media, 
          {image_standard_resolution_url: {$set: data.image_standard_resolution_url}},
          {image_thumbnail_url: {$set: data.image_thumbnail_url}},
          {fetching: {$set: data.fetching}}
        );
        updatedMedia.image_thumbnail_url = null
        updatedMedia.image = data.image
        if (updatedMedia.image && updatedMedia.image.standard_resolution && updatedMedia.image.standard_resolution.url) {
          updatedMedia.image.standard_resolution.url = `${updatedMedia.image.standard_resolution.url}?${Date.now()}`
        }

        var mediaIndex = _this.state.media.findIndex(function(m) {
          return m.id == media.id;
        });
        var newMedia = update(_this.state.media, {
          $splice: [[mediaIndex, 1, updatedMedia]]
        });

        _this.setState({
          media: newMedia
        });

        if (response.data.fetching) {
          setTimeout(function() { this.pollMedia(media) }.bind(this), 5000)
        }
      })
      .catch(error => {
        console.log('poll media error: ' + error)
      })
  }

  visit(media) {
    const taggedProduct = (media.link_type == 'product' && media.tagged_products.length > 0) ? media.tagged_products[0] : undefined
    const href = taggedProduct ? taggedProduct.uri : this.smartUri(media.uri)
    window.location = href
  }

  smartUri(uri) {
    if (!/^https?:\/\//i.test(uri)) {
      return 'http://' + uri;
    }
    return uri
  }

  render() {
    return(
      <div className="linkable-media-grid">
        <MediaGrid media={this.state.media}
                   onClick={(media) => this.handleMediaClick(media)}
                   onRefresh={(media) => this.handleMediaRefresh(media)} />
        {(this.state.status !== 'done' && this.state.status !== 'fetching' && this.state.media.length >= mediaPerPage) && 
          <div className="pagination"><a href="#" ref={(button) => this.button = button} className="btn btn--load-more" onClick={(e) => this.loadMore(e)}>Load more</a></div>
        }
        {this.state.status === 'fetching' &&
          <div className="loader">Loading...</div>
        }
      </div>
    );
  }

}