import React, { PropTypes } from 'react';
import css from './MediaItem.scss'
import moment from 'moment'

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

export default class MediaItem extends React.Component {
  constructor(props, _railsContext) {
    super(props);
  }

  componentDidMount() {
    // Check if we need to refetch the media to get fresh data
    var refetch = false
    const threshold = moment().subtract(1, 'days')
    // const threshold = moment().subtract(1, 'seconds') // testing
    if (this.props.media.fetched_at) {
      const fetchedAt = moment(this.props.media.fetched_at)
      if (fetchedAt.isBefore(threshold)) {
        refetch = true
      }
    } else {
      refetch = true
    }

    // do not refresh if image is cached
    if (this.props.media.image && this.props.media.image.standard_resolution.url) {
      refetch = false;
    }

    if (refetch) {
      this.props.onRefresh(this.props.media)
    }
  }

  onClick(e) {
    e.preventDefault();
    this.props.onClick(this.props.media);
  }

  onRefreshClick(e) {
    e.preventDefault();
    this.props.onRefresh(this.props.media);
  }

  onDisabledClick(e) {
    e.preventDefault();
  }

  render() {
    const taggedProduct = this.props.media.tagged_products.length > 0 ? this.props.media.tagged_products[0] : undefined

    // const url = (this.props.media.image && this.props.media.image.standard_resolution.url) ? this.props.media.image.standard_resolution.url : this.props.media.image_standard_resolution_url
    const instagramUrl = this.props.media.image_thumbnail_url ? this.props.media.image_thumbnail_url : this.props.media.image_standard_resolution_url
    const url = (this.props.media.image && this.props.media.image.standard_resolution.url) ? this.props.media.image.standard_resolution.url : instagramUrl

    const mediaStyle = {
      backgroundImage: 'url(' + url + ')',
    }
    const link = taggedProduct ? taggedProduct.uri : this.props.media.uri
    const isProduct = this.props.media.link_type == 'product'
    const isActive = (!isProduct && this.props.media.uri) || (isProduct && taggedProduct)

    return(
      <div className={'media-item' + (isActive ? '' : ' inactive')}>
        <div className="media-item__image" style={mediaStyle}>
          <div className="media-item__padding"></div>
          {isProduct && taggedProduct &&
            <div className="media-item__price">
              <i className="fa fa-shopping-bag" aria-hidden="true"></i>
              <div className="media-item__product-info">
                <span className="media-item__product-amount">{currencyFormatter.format(taggedProduct.product.amount / 100.0)}</span>
              </div>
            </div>
          }
          <a className="media-item__link" 
             href={link} 
             onClick={(e) => this.onClick(e)}></a>
        </div>
      </div>
    );
  }
}