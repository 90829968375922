import React, { PropTypes } from 'react';
import MediaItem from './MediaItem/MediaItem.jsx';
import css from './MediaGrid.scss'

export default class MediaGrid extends React.Component {
  constructor(props, _railsContext) {
    super(props);
  }

  handleMediaClick(media) {
    this.props.onClick(media)
  }

  handleMediaRefresh(media) {
    this.props.onRefresh(media)
  }

  render() {
    return(
      <div className={'media-grid' + (this.props.editable ? ' editable' : '')}>
        { this.props.media.chunk(3).map((row) =>
          <div className="media-grid__row" key={row[0].id}>
            { row.map((media) =>
              <div className='media-grid__column' key={media.id}>
                <MediaItem media={media} 
                           onClick={(media) => this.handleMediaClick(media)}
                           onRefresh={(media) => this.handleMediaRefresh(media)} />
              </div>
            )}
          </div>  
        )}
      </div>
    )
  }

}

Array.prototype.chunk = function (groupsize) {
  var sets = [];
  var chunks = this.length / groupsize;
  for (var i = 0, j = 0; i < chunks; i++, j += groupsize) {
    sets[i] = this.slice(j, j + groupsize);
  }
  return sets;
};