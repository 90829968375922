import React, { PropTypes } from 'react';
import ReactOnRails from 'react-on-rails'
import update from 'immutability-helper';
import css from './IdentityHeader.scss'
import request from 'axios'
import moment from 'moment'

export default class IdentityHeader extends React.Component {

  constructor(props, _railsContext) {
    super(props);
    this.state = {
      identity: this.props.identity
    };
  }

  componentDidMount() {
    // Check if we need to refetch the identity to get fresh data
    // var refetch = false;
    // const threshold = moment().subtract(7, 'days');
    // if (this.state.identity.fetched_at != null) {
    //   const fetchedAt = moment(this.state.identity.fetched_at);
    //   if (fetchedAt.isBefore(threshold)) {
    //     refetch = true;
    //   }
    // } else {
    //   refetch = true;
    // }
    // if (refetch) {
    //   this.refreshIdentity();
    // }
  }

  refreshIdentity() {
    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }
    request
      .put('/app/identities/' + this.state.identity.id + '/refresh.json', requestConfig)
      .then((response) => {
        const newIdentity = update(this.state.identity, {$set: response.data}) 
        this.setState({ identity: newIdentity })
      })
      .catch(error => {
        console.log('refresh identity error: ' + error)
      })
  }

  render() {
    // return "http://#{self.link}" unless self.link =~ /^https?:\/\//
    let smartLink = this.state.identity.link;
    if (smartLink !== null && !/^https?:\/\//.test(smartLink)) smartLink = 'http://' + smartLink;
    // link.sub(/^https?\:\/\//, '').sub(/^www./,'') if link.present?
    let cleanLink = this.state.identity.link;
    if (cleanLink !== null) cleanLink = cleanLink.replace(/^https?\:\/\//, '').replace(/^www./, '');
    const avatarUrl = (this.state.identity.avatar && this.state.identity.avatar.url) ? this.state.identity.avatar.url : this.state.identity.profile_picture
    return(
      <div>
        <header className="profile">
          <div className="profile__container">
            {avatarUrl &&
              <img src={avatarUrl} className="profile__picture" alt="" />
            }
            <div className="profile__details">
              <h1 className="profile__username">{this.state.identity.username}</h1>
              {this.state.identity.full_name !== this.state.identity.username &&
                <h2 className="profile__full-name">{this.state.identity.full_name}</h2>
              }
              <div className="profile__bio">{this.state.identity.bio}</div>
              {this.state.identity.link &&
                <a href={smartLink} target="_blank" className="profile__link">{cleanLink}</a>
              }
            </div>
          </div>
        </header>
        <div className="link-page__info">{this.state.identity.info}</div>
      </div>
    );
  }

}